import { Button, Form, Segmented, Select, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import axios from 'utils/axios';
import configFile from 'config.json';
import { NotificationManager } from 'react-notifications';
import MenuTable from 'components/Table/MenuTable';
import { SelectStore } from 'components/Form/InputTypes';
import styled from 'styled-components';
import { tableMenus as firstColumns } from 'utils/tableModels';

const Menus = () => {
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [store, setStore] = useState(null);
  const [form] = Form.useForm();
  const [tabItem, setTabItem] = useState(null);
  const [columns, setColumns] = useState(firstColumns);
  const [tableHeight, setTableHeight] = useState('100vh');

  const headerRef = useRef(null);

  useEffect(() => {
    setData(null);
    setTabItem(null);
    setTableData([]);

    if (store) fetchData();
    return () => {
      setTableData([]);
    };
  }, [store]);

  useEffect(() => {
    if (data && data.length !== 0) {
      onChangeTab(Object.keys(data)[0]);
    }
  }, [data]);
  useEffect(() => {
    const node = headerRef.current;
    const { top } = node.getBoundingClientRect();
    // normally TABLE_HEADER_HEIGHT would be 55.
    setTableHeight(window.innerHeight - top);
  }, [headerRef]);

  const fetchData = async () => {
    try {
      const {
        data: { data: menus },
      } = await axios.post(`${configFile.PROD_SERVER_URL}/menu/getMenus`, {
        storeName: store,
      });
      setData(menus);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const handleStore = (item) => {
    setStore(item);
  };

  const onChangeTab = (e) => {
    setTabItem(e);
    if (e === 'Pizza') {
      let datas = [];
      Object.keys(data[e]).map((field) => {
        data[e][field].items.map((item) =>
          datas.push({ ...item, key: datas.length + 1 })
        );
      });
      setTableData(datas);
    } else {
      const mappedData = data[e].map((item, index) => {
        return { ...item, key: index + 1 };
      });
      setTableData(mappedData);
    }
  };
  const handleUpdateMenu = async () => {
    try {
      const {
        data: {
          data: { data: menus },
        },
      } = await axios.post(
        `${configFile.PROD_SERVER_URL}/menu/updateMenusFromStore`,
        {
          storeName: store,
        }
      );
      NotificationManager.success('Амжилттай татагдлаа', 5000);
      fetchData();
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  return (
    <Form form={form}>
      <Container>
        <div className='header' ref={headerRef}>
          <div className='top'>
            <SelectStore
              style={{ minWidth: '300px' }}
              form={form}
              name='store'
              onChange={handleStore}
            />
            <Button
              disabled={!store ? true : false}
              key='back1'
              onClick={handleUpdateMenu}
            >
              Салбараас меню татах
            </Button>
          </div>
          {data && data.length !== 0 && (
            <Tabs
              type='card'
              items={Object.keys(data).map((field, i) => {
                return {
                  label: field,
                  key: field,
                };
              })}
              onChange={onChangeTab}
            />
          )}
        </div>
        <MenuTable
          height={tableHeight}
          columns={columns}
          dataSource={tableData}
          menu={tabItem}
        />
      </Container>
    </Form>
  );
};
const Container = styled.div`
  .top {
    display: flex;
    gap: 20px;
  }
`;
export default Menus;
