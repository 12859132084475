import { Button, Tag } from "antd";
import { NotificationManager } from "react-notifications";
import { copyToClipBoard } from "./etc";
import { dateFormat } from "./format";
import { namedWeeklyday } from "./formatChange";
export const tableSchedule = [
  {
    title: "Салбар",
    dataIndex: "storeName",
    fixed: "left",
  },
  {
    title: "Гариг",
    dataIndex: "weekDay",
    render: (_) => namedWeeklyday(_),
    fixed: "left",
  },
  {
    title: "Эхлэх цаг",
    dataIndex: "startTime",
  },
  {
    title: "Дуусах цаг",
    dataIndex: "endTime",
  },
  {
    title: "Дараа өдөртөө орох",
    dataIndex: "endTimeShift",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Тийм" : "Үгүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Шилжүүлэх эсэх",
    dataIndex: "haveRedirect",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Тийм" : "Үгүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Шилжүүлэх салбар",
    dataIndex: "redirectStoreName",
  },
  {
    title: "Эхлэх цаг",
    dataIndex: "redirectStartTime",
  },
  {
    title: "Дуусах цаг",
    dataIndex: "redirectEndTime",
  },
  {
    title: "Дараа өдөртөө орох",
    dataIndex: "redirectEndTimeShift",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Тийм" : "Үгүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
];
export const tableStores = [
  {
    title: "Салбар",
    dataIndex: "storeNameMn",
    fixed: "left",
  },
  {
    title: "УЗ авах",
    dataIndex: "haveAdvanced",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Авна" : "Авахгүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
    onCell: (record, rowIndex) => ({
      onClick: () => {
        console.log(rowIndex);
      },
    }),
  },
  {
    title: "Хүргэлттэй",
    dataIndex: "haveDelivery",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Хүргэлттэй" : "Хүргэлтгүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Очиж авах",
    dataIndex: "havePickup",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Авна" : "Авахгүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Төлөв",
    dataIndex: "isActive",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Идэвхитэй" : "Идэвхигүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Үнэгүй хүргэлт хийгдэх",
    dataIndex: "freeDelivery",
  },
  {
    title: "Хүргэлт хийх захиалгын хамгийн бага дүн",
    dataIndex: "minDeliveryAmount",
  },
  {
    title: "Очиж авах захиалгын хамгийн бага дүн",
    dataIndex: "minPickupAmount",
  },
  {
    title: "Туслах мессеж",
    dataIndex: "quickMessage",
  },
  {
    title: "hrsUrl",
    dataIndex: "hrsUrl",
  },
  {
    title: "companyItemMasterName",
    dataIndex: "companyItemMasterName",
  },
  {
    title: "companyItemMasterObjNum",
    dataIndex: "companyItemMasterObjNum",
  },
  {
    title: "deliveryChgObjNum",
    dataIndex: "deliveryChgObjNum",
  },
  {
    title: "deliveryItemMasterName",
    dataIndex: "deliveryItemMasterName",
  },
  {
    title: "deliveryItemMasterObjNum",
    dataIndex: "deliveryItemMasterObjNum",
  },
  {
    title: "pickupItemMasterName",
    dataIndex: "pickupItemMasterName",
  },
  {
    title: "pickupItemMasterObjNum",
    dataIndex: "pickupItemMasterObjNum",
  },
  {
    title: "storeNo",
    dataIndex: "storeNo",
  },
];
export const tablePosCheck = [
  {
    title: "Салбар",
    dataIndex: "storeName",
    fixed: "left",
    width: 220,
  },
  {
    title: "Pos Url",
    dataIndex: "hrsUrl",
    width: 200,
  },
  {
    title: "Success",
    dataIndex: "success",
    width: 100,
    render: (_) => {
      let text = _;
      const color =
        _ === "pending" ? "black" : _ === true ? "green" : "volcano";
      return (
        <Tag color={color} key={_}>
          {text.toString().toUpperCase()}
        </Tag>
      );
    },
  },
];
export const tablePods = [
  {
    title: "Под код",
    dataIndex: "podCode",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Салбар",
    dataIndex: "storeName",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Засварласан огноо",
    dataIndex: "updated_at",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Төлөв ",
    dataIndex: "isActive",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Идэвхитэй" : "Идэвхигүй";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },

    // render: (text) => <a>{text}</a>,
  },
];
export const tableAddress = [
  {
    title: "Хот",
    dataIndex: "city",
  },
  {
    title: "Дүүрэг",
    dataIndex: "district",
  },
  {
    title: "Хороо",
    dataIndex: "horoo",
  },
  {
    title: "Под РH",
    dataIndex: "podph",
  },
  {
    title: "Хаяг",
    dataIndex: "bairname",
  },
  {
    title: "Байршил",
    dataIndex: "geopoint",
  },
];
export const tableMenus = [
  {
    title: "masterName",
    dataIndex: "masterName",
    onFilter: (value, record) => record["masterName"].indexOf(value) === 0,
    rowScope: "row",
    width: 200,
  },
  {
    title: "miMasterObjNum",
    dataIndex: "miMasterObjNum",
    sorter: (a, b) => a["miMasterObjNum"] - b["miMasterObjNum"],
    width: 180,
  },
  {
    title: "menuItemDefID",
    dataIndex: "menuItemDefID",
    width: 150,
  },
  {
    title: "longDescriptor_stringText",
    dataIndex: "longDescriptor_stringText",
    width: 200,
  },
  {
    title: "familyGroup",
    dataIndex: "familyGroup",
    onFilter: (value, record) => record["familyGroup"].indexOf(value) === 0,
    width: 160,
  },
  {
    title: "familyGroupId",
    dataIndex: "familyGroupId",
    onFilter: (value, record) => record["familyGroupId"].indexOf(value) === 0,
    sorter: (a, b) => a["familyGroupId"] - b["familyGroupId"],
    width: 160,
  },
  {
    title: "majorGroup",
    dataIndex: "majorGroup",
    onFilter: (value, record) => record["majorGroup"].indexOf(value) === 0,
    sorter: (a, b) => a["majorGroup"] - b["majorGroup"],
    width: 160,
  },
  {
    title: "majorGroupId",
    dataIndex: "majorGroupId",
    onFilter: (value, record) => record["majorGroupId"].indexOf(value) === 0,
    width: 160,
  },
  {
    title: "menuItemMasterID",
    dataIndex: "menuItemMasterID",
    sorter: (a, b) => a["menuItemMasterID"] - b["menuItemMasterID"],
    width: 180,
  },
  {
    title: "price",
    dataIndex: "price",
    sorter: (a, b) => a["price"] - b["price"],
    width: 120,
  },
  {
    title: "type",
    dataIndex: "type",
    width: 120,
  },
  {
    title: "subLevelsHex",
    dataIndex: "subLevelsHex",
    width: 200,
  },
];
export const tableCombo = [
  {
    title: "Д/д",
    dataIndex: "key",
    rowScope: "row",
  },
  {
    title: "masterName",
    dataIndex: "masterName",
  },
  {
    title: "active",
    dataIndex: "active",
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "Тийм" : "Үгүй";
      return (
        <Tag color={color} key={text}>
          {text}
        </Tag>
      );
    },
  },
  {
    title: "comboMenuId",
    dataIndex: "comboMenuId",
  },
  {
    title: "primaryComboGroupId",
    dataIndex: "primaryComboGroupId",
  },
  {
    title: "storeName",
    dataIndex: "storeName",
  },
];
export const tableTranslate = [
  {
    title: "Англи",
    dataIndex: "en",
  },
  {
    title: "Монгол",
    dataIndex: "mn",
  },
];
export const tableUser = [
  {
    title: "Хэрэглэгчийн нэр",
    dataIndex: "username",
    fixed: "left",
  },
  {
    title: "Төлөв",
    dataIndex: "userStatus",
    sorter: (a, b) => a.userStatus?.localeCompare(b.userStatus),
    render: (_) => {
      const color = "geekblue";
      return (
        <Tag color={color} key={_}>
          {_.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Үүсгэсэн огноо",
    dataIndex: "userCreateDate",
    render: (_) => {
      const date = new Date(_);
      return dateFormat(date);
    },
    sorter: (a, b) =>
      new Date(a["userCreateDate"]) - new Date(b["userCreateDate"]),
  },
  {
    title: "Сүүлд өөрчлөлт орсон огноо",
    dataIndex: "userLastModifiedDate",
    render: (_) => {
      const date = new Date(_);
      return dateFormat(date);
    },
    sorter: (a, b) =>
      new Date(a["userLastModifiedDate"]) - new Date(b["userLastModifiedDate"]),
  },
  {
    title: "Enabled",
    dataIndex: "enabled",
    sorter: (a, b) => {
      `${a["enabled"]}`.localeCompare(`${b["enabled"]}`);
    },
    render: (_) => {
      const color = _ ? "green" : "volcano";
      const text = _ ? "TRUE" : "FALSE";
      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "lastEventCreationDate",
    dataIndex: "lastEventCreationDate",
    sorter: (a, b) =>
      new Date(a["lastEventCreationDate"]) -
      new Date(b["lastEventCreationDate"]),
    render: (_) => {
      const date = new Date(_);
      return dateFormat(date);
    },
  },
  {
    title: "lastEventResponse",
    dataIndex: "lastEventResponse",
    sorter: (a, b) => a.lastEventResponse?.localeCompare(b.lastEventResponse),
  },
  {
    title: "lastEventType",
    dataIndex: "lastEventType",
    sorter: (a, b) => a.lastEventType?.localeCompare(b.lastEventType),
  },
  {
    title: "lastIpAddress",
    dataIndex: "lastIpAddress",
    sorter: (a, b) => a.lastIpAddress?.localeCompare(b.lastIpAddress),
  },
];
export const tableImages = [
  {
    title: "Нэр",
    dataIndex: "name",
  },
  {
    title: "Зураг",
    dataIndex: "url",
    width: "250px",
    render: (_) => {
      return <img style={{ width: "100%" }} src={_} />;
    },
  },
  {
    title: "Зурагны зам",
    dataIndex: "url",
    render: (_) => {
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          <a
            style={{
              whiteSpace: "nowrap",
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            href={_}
            target="_blank"
            rel="noreferrer"
          >
            {_}
          </a>
          <Button onClick={(e) => copyToClipBoard(_)}>Copy</Button>
        </div>
      );
    },
  },
  {
    title: "Сүүлд өөрчлөлт орсон огноо",
    dataIndex: "updatedAt",
    render: (_) => {
      const date = new Date(_);
      return dateFormat(date);
    },
  },
];

export const dashboardModels = [
  {
    name: "sales",
    title: "Борлуулалт",
    table: [
      {
        title: "Салбар",
        dataIndex: "store",
      },
      {
        title: "Тоо",
        dataIndex: "quantity",
      },
      {
        title: "sales",
        dataIndex: "Борлуулалт",
      },
    ],
  },
  {
    name: "kpi",
    title: "KPI",
    table: [
      {
        title: "Салбар",
        dataIndex: "store",
      },
      {
        title: "AD",
        dataIndex: "ad",
      },
      {
        title: "In Store",
        dataIndex: "instore",
      },
    ],
  },
  {
    name: "",
    title: "Гомдол",
    table: [
      {
        title: "Салбар",
        dataIndex: "store",
      },
      {
        title: "Тоо",
        dataIndex: "quantity",
      },
      {
        title: "sales",
        dataIndex: "Борлуулалт",
      },
    ],
  },
  {
    name: "sales",
    title: "Борлуулалт",
    table: [
      {
        title: "Салбар",
        dataIndex: "store",
      },
      {
        title: "Тоо",
        dataIndex: "quantity",
      },
      {
        title: "sales",
        dataIndex: "Борлуулалт",
      },
    ],
  },
];
