import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import configFile from "config.json";
import axios from "utils/axios";
import styled from "styled-components";
import { SelectStore } from "components/Form/InputTypes";
import { Button, Checkbox, Divider, Form, Tag } from "antd";
import MenuTable from "components/Table/ComboTable";
import { tableCombo as columns } from "utils/tableModels";
import { ShowCombo, CreateCombo } from "components/Modal/Combo";
import { PlusOutlined } from "@ant-design/icons";
const CheckboxGroup = Checkbox.Group;

const CopyCombo = () => {
  const [combos, setCombos] = useState([]);
  const [comboOptions, setComboOptions] = useState([]);
  const [form] = Form.useForm();
  const [store, setStore] = useState();
  const [stores, setStores] = useState();

  const [checkedFromList, setCheckedFromList] = useState([]);
  const [indeterminateFrom, setIndeterminateFrom] = useState(true);
  const [checkAllFrom, setCheckAllFrom] = useState(false);
  const [checkedToList, setCheckedToList] = useState([]);
  const [indeterminateTo, setIndeterminateTo] = useState(true);
  const [checkAllTo, setCheckAllTo] = useState(false);

  useEffect(() => {
    setCombos([]);
    setCheckedFromList([]);
    setCheckedToList([]);
    if (store) {
      fetchDataGetCombos();
    }
  }, [store]);

  const fetchDataGetCombos = async () => {
    try {
      const {
        data: { data },
      } = await axios.post(`${configFile.PROD_SERVER_URL}/menu/getComboMenus`, {
        storeName: store,
      });
      const options = data.map((item, index) => ({
        label: (
          <div>
            {item.masterName}
            <Tag
              color={item.active ? "green" : "volcano"}
              key={index}
            >
              {item.active ? "Харагдана" : "Харагдахгүй"}
            </Tag>
          </div>
        ),
        value: item.id,
      }));
      setCombos(data);
      setComboOptions(options);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
    }
  };
  const handleStore = (item) => {
    setStore(item);
  };
  const onCheckAllChangeFrom = (e) => {
    setCheckedFromList(
      e.target.checked ? comboOptions.map((item) => item.value) : []
    );
    setIndeterminateFrom(false);
    setCheckAllFrom(e.target.checked);
  };
  const onCheckAllChangeTo = (e) => {
    setCheckedToList(e.target.checked ? stores : []);
    setIndeterminateTo(false);
    setCheckAllTo(e.target.checked);
  };
  const onChangeFrom = (list) => {
    setCheckedFromList(list);
    setIndeterminateFrom(!!list.length && list.length < comboOptions.length);
    setCheckAllFrom(list.length === comboOptions.length);
  };

  const onChangeTo = (list) => {
    setCheckedToList(list);
    setIndeterminateTo(!!list.length && list.length < stores.length);
    setCheckAllTo(list.length === stores.length);
  };

  const handleCopy = async () => {
    // const fromIds = checkedFromList.map(
    //   (item) => combos?.filter((combo) => combo.masterName === item)[0].id
    // );
    const to = checkedToList.map((item) => ({ storeName: item }));
    const from = {
      storeName: store,
      ids: checkedFromList,
    };

    try {
      await axios.post(`${configFile.PROD_SERVER_URL}/menu/copyComboMenus`, {
        from,
        to,
      });
      NotificationManager.success("Амжилттай хууллаа");
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
    }

    console.log(from, to);
  };

  return (
    <Form form={form}>
      <Container>
        <TopHead>
          <SelectStore
            form={form}
            name="store"
            setStores={setStores}
            onChange={handleStore}
          />
        </TopHead>
        <Content>
          <div className="from">
            <Checkbox
              indeterminate={indeterminateFrom}
              onChange={onCheckAllChangeFrom}
              checked={checkAllFrom}
            >
              Check all
            </Checkbox>
            <Divider />
            <StyledCheckboxGroup
              options={comboOptions}
              value={checkedFromList}
              onChange={onChangeFrom}
            />
          </div>
          <div className="">
            <Button
              onClick={handleCopy}
              disabled={
                checkedFromList.length === 0 || checkedToList.length === 0
              }
            >
              Хуулах
            </Button>
          </div>
          <div className="to">
            <Checkbox
              indeterminate={indeterminateTo}
              onChange={onCheckAllChangeTo}
              checked={checkAllTo}
            >
              Check all
            </Checkbox>
            <Divider />
            <StyledCheckboxGroup
              options={stores?.filter((item) => item !== store)}
              value={checkedToList}
              onChange={onChangeTo}
            />
          </div>
        </Content>

        {/* {store && (
          <MenuTable
            columns={columns}
            dataSource={tableData}
            handleAction={handleAction}
            menus={menus}
            deleteCombo={deleteCombo}
            fetchData={fetchData}
          />
        )}
        {isModalOpen && type === 'edit' && (
          <ShowCombo
            // width={800}
            isShowModal={isModalOpen}
            setIsShowModal={setIsModalOpen}
            cancelButtonText='Хаах'
            okButtonText='Хадгалах'
            instance={instance}
            title={`${instance['masterName']} багц засах`}
            rawMainItems={instance.rawMainItems}
            rawSideItems={instance.rawSideItems}
            type={type}
            store={store}
            menus={menus}
            fetchData={fetchData}
          />
        )}
        {isModalOpen && type === 'create' && (
          <ShowCombo
            // width={800}
            isShowModal={isModalOpen}
            setIsShowModal={setIsModalOpen}
            cancelButtonText='Хаах'
            okButtonText='Хадгалах'
            title={`Шинэ багц үүсгэх`}
            // rawMainItems={instance.rawMainItems}
            // rawSideItems={instance.rawSideItems}
            type={type}
            store={store}
            menus={menus}
            fetchData={fetchData}
          />
        )}
        {isModalOpen && type === 'show' && (
          <ShowCombo
            // width={800}
            isShowModal={isModalOpen}
            setIsShowModal={setIsModalOpen}
            okButtonText='Ok'
            cancelButtonText='Хаах'
            instance={instance}
            title={`${instance['masterName']} мэдээлэл`}
            rawMainItems={instance.rawMainItems}
            rawSideItems={instance.rawSideItems}
            type={type}
            store={store}
            menus={menus}
          />
        )} */}
      </Container>
    </Form>
  );
};
const Content = styled.div`
  display: flex;
  gap: 100px;
`;
const Container = styled.div`
  width: 100%;
  background: #fff;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TopHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    margin: 0 !important;
  }
`;
export default CopyCombo;
