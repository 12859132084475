import './App.css';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as theme from './utils/theme';
import { ThemeProvider } from 'styled-components';
import { AuthContextProvider } from './contexts/auth';
import { ReferenceContextProvider } from './contexts/reference';
import AuthEntry from './pages/entry';
import StoreSettings from 'pages/Settings/Store';
import ScheduleSettings from 'pages/Settings/Schedule';
import PodSettings from 'pages/Settings/Pod';
import PosCheckStore from 'pages/Settings/PosCheck';
import Login from 'pages/__Login';
import Register from 'pages/__Register';
import { Amplify } from 'aws-amplify';
import configFile from 'config.json';
import { NotificationContainer } from 'react-notifications';
import Address from 'pages/Address/Address';
import Location from 'pages/Address/Location';
import SearchLocation from 'pages/Address/SearchLocation';
import Menus from 'pages/Menus/Menus';
import Combo from 'pages/Menus/Combo';
import CopyCombo from 'pages/Menus/CopyCombo';
import ImagePage from 'pages/Menus/Image';
import Log from 'pages/Menus/Log';
import Users from 'pages/Users/Users';
import Profile from 'pages/Profile/Profile';
import Translate from 'pages/Menus/Translate';
import Dashboard from 'pages/Dashboard/Dashboard';
import Logout from 'components/Logout';
import React from 'react';
// import Menus from 'components/Menu';
Amplify.configure(configFile.awsmobile);

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <ReferenceContextProvider>
            <Routes>
              <Route path='/' element={<AuthEntry />}>
                <Route path='/stores-settings' element={<StoreSettings />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route
                  path='/schedule-settings'
                  element={<ScheduleSettings />}
                />
                <Route path='/pod-settings' element={<PodSettings />} />
                <Route path='/pos-check' element={<PosCheckStore />} />
                <Route path='/edit-address' element={<Address />} />
                <Route path='/edit-location' element={<Location />} />
                <Route path='/search-location' element={<SearchLocation />} />
                <Route path='/list' element={<Menus />} />
                <Route path='/combo' element={<Combo />} />
                <Route path='/copyCombo' element={<CopyCombo />} />
                <Route path='/translate' element={<Translate />} />
                <Route path='/images' element={<ImagePage />} />
                <Route path='/log' element={<Log />} />
                <Route path='/users' element={<Users />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/logout' element={<Logout />} />
              </Route>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
            </Routes>
          </ReferenceContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
      <NotificationContainer />
    </Router>
  );
}

export default App;
